import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../context/ThemeContext/ThemeContext';
import { useAuth } from '../../context/AuthContext/AuthContext';

import useDocumentTitle from '../../hooks/useDocumentTitle';

import { fetchProfile, sendDiscordTag } from '../../services/discord/discord';
import { logout } from '../../services/auth/login';

import { Profile } from '../../services/discord/interfaces';

import {
  Button,
  Chip,
  Paper,
  Stack,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import Error from '../../components/Error/Error';

import EastIcon from '@mui/icons-material/East';
import CircleIcon from '@mui/icons-material/Circle';
import InfoIcon from '@mui/icons-material/Info';

import DiscordLogoImage from '../../assets/images/discord/logo-discord.png';
import LogoRmfImage from '../../assets/images/discord/logo-rmf.png';
import VidePoster from '../../assets/images/discord/video-poster.png';

import classes from './discord.module.scss';

const Discord = () => {
  const { isDarkMode } = useTheme();
  const { tokens, isTokensLoading, user } = useAuth();
  const { t } = useTranslation(['discord']);

  const [profile, setProfile] = useState<Profile>();
  const [discordTag, setDiscordTag] = useState<string>('');
  const [error, setError] = useState<string>('');

  useDocumentTitle(`Discord`);

  const handleChange = (event: any) => {
    setDiscordTag(event.target.value);
  };

  const handleSendDiscordTag = () => {
    if (!isTokensLoading && tokens?.accessToken && user) {
      if (checkDiscordTag(discordTag)) {
        sendDiscordTag(tokens.accessToken, user.userId, discordTag);
      } else {
        setError(t('INVALID_USERNAME'));
      }
    }
  };

  const checkDiscordTag = (tag: string) => {
    const lowerCasDiscordTag = tag.toLowerCase();

    if (lowerCasDiscordTag.length < 2 || lowerCasDiscordTag.length > 32) {
      return false;
    }

    const regex = /^[a-z0-9_.]+$/;
    const regexDoublePoint = /\.{2,}/;
    if (
      !regex.test(lowerCasDiscordTag) ||
      regexDoublePoint.test(lowerCasDiscordTag)
    ) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (!isTokensLoading && tokens?.accessToken && user) {
      const fetchData = async () => {
        try {
          const profileData = await fetchProfile(
            tokens.accessToken,
            user.userId,
          );
          setProfile(profileData);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    } else if (!tokens) {
      logout();
    }
  }, [tokens, isTokensLoading]);

  const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
  });

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          position: 'relative',
          height: '40vh',
          marginBottom: '80px',
          borderRadius: '40px',
          background:
            'radial-gradient(circle, rgba(211,84,84,1) 0%, rgba(207,53,53,1) 100%)',
        }}
      >
        <img
          className={classes.logo1}
          src={LogoRmfImage}
          style={{
            position: 'absolute',
            height: 'clamp(150px, 10vw, 10vw',
            left: '-65px',
            width: 'auto',
            zIndex: 10,
          }}
        />
        <img
          className={classes.logo2}
          src={DiscordLogoImage}
          style={{
            position: 'absolute',
            bottom: '-50px',
            right: '0px',
            height: 'clamp(300px, 19vw, 19vw',
            width: 'auto',
          }}
        />
        <Stack
          justifyContent={'center'}
          alignContent={'center'}
          alignItems={'center'}
          height={'100%'}
        >
          <Typography
            variant="h1"
            color={'white'}
            fontFamily={'Inter'}
            fontWeight={'bold'}
            fontSize={'clamp(2.5rem, 4vw, 8vw)'}
            textAlign={'center'}
            mb={8}
            zIndex={20}
          >
            {t('JOIN_OUR_COMMUNITY')}
          </Typography>
          <Button
            href="https://discord.gg/wM53dXyj"
            target="_blank"
            variant="outlined"
            size="large"
            sx={{
              color: 'white',
              border: '2px solid white',
              borderRadius: '25px',
              padding: '12px 24px 10px 24px',
              display: 'flex',
              gap: '20px',
              svg: {
                transition: 'transform 0.2s',
              },
              '&:hover': {
                border: '2px solid white',
                backgroundColor: 'white',
                color: 'black',

                svg: {
                  transform: 'translateX(8px)',
                },
              },
            }}
          >
            {t('JOIN_BUTTON')} <EastIcon fontSize="medium" />
          </Button>
        </Stack>
      </Paper>

      <Stack
        direction={'row'}
        gap={12}
        justifyContent={'space-between'}
        sx={{
          '@media (max-width: 1024px)': {
            flexDirection: 'column',
          },
        }}
      >
        <Stack
          width={'50%'}
          sx={{
            '@media (max-width: 1440px)': {
              width: '100%',
            },
          }}
        >
          <Typography variant="h5" mb={2}>
            {t('CLAIM_YOUR_ROLE')}
          </Typography>
          <Paper
            elevation={0}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              flexDirection: 'column',
              padding: '1vw',
              backgroundColor: isDarkMode ? '#262729' : '#FFFFFF',
              color: isDarkMode ? '#FFFFFF' : '#262729',
              paddingX: '24px',
              paddingY: '24px',
              aspectRatio: 'auto',
              '@media (min-width: 1440px) and (max-width: 1700px)': {
                aspectRatio: '16 / 9',
              },
            }}
          >
            <Stack direction={'row'} alignItems={'center'}>
              <Stack>
                <Typography mb={2} fontWeight={'bold'} variant="h4">
                  {profile?.fullName}
                </Typography>
                <Typography mb={1} variant="h5">
                  {t('ROLES_AVAILABLE_FOR_YOU')}
                </Typography>
                <Stack direction={'row'} mb={4} flexWrap={'wrap'} gap={1}>
                  {profile?.funded ? (
                    <Chip
                      icon={
                        <CircleIcon
                          fontSize="small"
                          color="primary"
                          sx={{
                            color: '#FFCC00',
                          }}
                        />
                      }
                      label="📈 - Funded"
                      sx={{
                        color: isDarkMode ? '#FFFFFF' : '#262729',
                        backgroundColor: isDarkMode ? '#424242' : '#c5c5c5',
                        fontSize: '16px',
                        padding: '16px',
                      }}
                    />
                  ) : null}
                  {profile?.hasPhase2 ? (
                    <Chip
                      icon={
                        <CircleIcon
                          fontSize="small"
                          color="primary"
                          sx={{ color: '#02B3DA' }}
                        />
                      }
                      label="🎖️ - Phase 2"
                      sx={{
                        color: isDarkMode ? '#FFFFFF' : '#262729',
                        backgroundColor: isDarkMode ? '#424242' : '#c5c5c5',
                        fontSize: '16px',
                        padding: '16px',
                      }}
                    />
                  ) : null}
                  {profile?.hasPhase1 ? (
                    <Chip
                      icon={
                        <CircleIcon
                          fontSize="small"
                          color="primary"
                          sx={{ color: '#2ECC71' }}
                        />
                      }
                      label="💸 - Phase 1"
                      sx={{
                        color: isDarkMode ? '#FFFFFF' : '#262729',
                        backgroundColor: isDarkMode ? '#424242' : '#c5c5c5',
                        fontSize: '16px',
                        padding: '16px',
                      }}
                    />
                  ) : null}
                  {profile?.funded || profile?.hasPhase1 || profile?.hasPhase2
                    ? null
                    : t('YOU_DONT_HAVE_AVAILABLE_ROLES')}
                </Stack>

                <Stack
                  alignItems={'center'}
                  direction={'row'}
                  spacing={1}
                  mb={1}
                >
                  <Typography
                    // color={isDarkMode ? '#FFFFFF' : '#262729'}
                    color={'#A2ACBD'}
                    fontWeight={'bold'}
                    variant="h6"
                  >
                    {t('DISCORD_USERNAME')}
                  </Typography>
                  <NoMaxWidthTooltip
                    placement="top"
                    title={t('TOOLTIP_MESSAGE')}
                  >
                    <InfoIcon
                      fontSize="small"
                      // sx={{ color: isDarkMode ? '#FFFFFF' : '#262729' }}
                      sx={{ color: '#A2ACBD' }}
                    />
                  </NoMaxWidthTooltip>
                </Stack>

                {error && <Error error={error} />}

                <Stack
                  direction={'row'}
                  gap={2}
                  sx={{
                    '@media (max-width: 426px)': {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <TextField
                    fullWidth
                    id="discord-username"
                    size="small"
                    variant="outlined"
                    color="primary"
                    value={discordTag}
                    onChange={handleChange}
                    placeholder={t('DISCORD_USERNAME')}
                    InputLabelProps={{ style: { color: 'white' } }}
                    InputProps={{
                      style: {
                        borderColor: '#A2ACBD',
                        color: isDarkMode ? '#FFFFFF' : '#262729',
                      },
                    }}
                    sx={{
                      '& label.Mui-focused': {
                        color: isDarkMode ? '#FFFFFF' : '#262729',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#A2ACBD',
                        },
                        '&:hover fieldset': {
                          borderColor: '#A2ACBD',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#A2ACBD',
                        },
                      },
                      '& .MuiInputBase-input': {
                        color: isDarkMode ? '#FFFFFF' : '#262729',
                      },
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ paddingLeft: '42px', paddingRight: '42px' }}
                    onClick={() => handleSendDiscordTag()}
                  >
                    {t('SEND')}
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Paper>
        </Stack>

        <Stack
          width={'50%'}
          sx={{
            '@media (max-width: 1440px)': {
              width: '100%',
              marginLeft: '0px !important',
            },
          }}
        >
          <Typography variant="h5" mb={2}>
            {t('WELCOME_TO_THE_DISCORD')}
          </Typography>
          <video
            style={{
              width: '100%',
              aspectRatio: '16 / 9',
            }}
            controls
            src={require('../../assets/videos/discord.mp4')}
            poster={VidePoster}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default Discord;
