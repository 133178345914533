import { useState, useRef, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { useTheme } from '../../context/ThemeContext/ThemeContext';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../context/AuthContext/AuthContext';

import useDocumentTitle from '../../hooks/useDocumentTitle';
import { toast } from 'react-toastify';

import {
  fetchClientAreaData,
  modifyPassword,
  submitWithdrawalRequest,
  upgradeAccount,
} from '../../services/client/client';
import { logout } from '../../services/auth/login';
import { fetchProfile } from '../../services/profileSidebar/profileSidebar';

import Tag from '../../components/userInterface/Tag/Tag';
import Tooltip from '../../components/userInterface/Tooltip/Tooltip';

import IconCard from '../../components/Cards/IconCard/IconCard';
import AccountCard from '../../components/Cards/AccountCard/AccountCard';
import Card from '../../components/Cards/Card/Card';
import Icon from '../../components/userInterface/Icon/Icon';
import Button from '../../components/userInterface/Button/Button';
import ProfitChart from '../../components/ProfitChart/ProfitChart';
import Modal from '../../components/Modal/Modal';
import Loader from '../../components/Loader/Loader';
import classes from './_clientArea.module.scss';
import Stepper from '../../components/Stepper/Stepper';
import Select from '../../components/userInterface/Select/Select';
import Error from '../../components/Error/Error';
import Input from '../../components/userInterface/Input/Input';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Popover from '@mui/material/Popover';
import ZoomableChart from '../../components/ZoomableChart/ZoomableChart';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import MuiTooltip from '@mui/material/Tooltip';
import MUIModal from '@mui/material/Modal';
import EditIcon from '@mui/icons-material/Edit';
import MUIButton from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import PDFViewer from '../Certificate/components/PDFViewer/PDFViewer';

import networkList from '../PartnerArea/networkList.json';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

import ContractPDF from '../../assets/images/certificate/Independant_Contractor.pdf';

interface Account {
  accountLogin: string;
  accountPassword: string;
}

export default function ClientArea() {
  const { isDarkMode } = useTheme();
  const { user, tokens, isTokensLoading } = useAuth();
  const { t } = useTranslation(['clientArea', 'components', 'common']);
  const navigate = useNavigate();

  const passwordRef = useRef<HTMLInputElement | null>(null);
  const idRef = useRef<HTMLParagraphElement>(null);
  const serverRef = useRef<HTMLParagraphElement>(null);
  const prevTokenRef = useRef<string | null>(null);

  const [isModalOpen, setIsModalOpen] = useState<boolean[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean[]>([]);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] =
    useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [accounts, setAccounts] = useState<any>([]);
  const [wallet, setWallet] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [walletValue, setWalletValue] = useState<string>('');
  const [kycStatus, setKycStatus] = useState<boolean>(false);

  const [isWalletValid, setIsWalletValid] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [amount, setAmount] = useState('');
  const [selectedNetwork, setSelectedNetwork] = useState<string | null>(null);

  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);

  const [showContract, setShowContract] = useState<boolean>(false);
  const [selectedAccountLogin, setSelectedAccountLogin] = useState<string>('');

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openGetFunded, setOpenGetFunded] = useState(false);
  const handleOpenGetFunded = (accountLogin: string) => {
    setSelectedAccountLogin(accountLogin);
    setOpenGetFunded(true);
  };
  const handleCloseGetFunded = () => setOpenGetFunded(false);

  const [openCredentialsModal, setOpenCredentialsModal] = useState<boolean[]>(
    [],
  );
  const handleOpenCredentialsModal = (accountLogin: string) =>
    setOpenCredentialsModal({ ...isModalOpen, [accountLogin]: true });
  const handleCloseCredentialsModal = (accountLogin: string) =>
    setOpenCredentialsModal({ ...isModalOpen, [accountLogin]: false });

  const [openModifyPasswordDialog, setOpenModifyPasswordDialog] = useState<
    boolean[]
  >([]);
  const handleOpenModifyPasswordDialog = (accountLogin: string) => {
    setOpenModifyPasswordDialog({
      ...isDialogOpen,
      [accountLogin]: true,
    });
  };
  const handleCloseModifyPasswordDialog = (accountLogin: string) => {
    setOpenModifyPasswordDialog({
      ...isDialogOpen,
      [accountLogin]: false,
    });
  };

  useDocumentTitle('Client Area');

  const steps = [
    { title: t('withdrawFunds.steps.paiementMethod') },
    { title: t('withdrawFunds.steps.informations') },
    { title: t('withdrawFunds.steps.amount') },
  ];
  const paymentMethod = [
    t('withdrawFunds.stepOne.selectPaiementMethodPlaceholder'),
    t('withdrawFunds.stepOne.bankAccount'),
    t('withdrawFunds.stepOne.cryptoWallet'),
  ];

  const upgradeAccountPhase = async (account: any, tokens: any) => {
    try {
      const response = await upgradeAccount(account?.challengeUUID);
      if (response.status === 200) {
        toast.success('Account successfully upgraded!', {
          progressStyle: { backgroundColor: 'green' },
        });
      }

      window.location.reload();
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const fetchData = async () => {
    if (tokens && tokens.accessToken !== prevTokenRef.current) {
      setIsLoading(true);
      prevTokenRef.current = tokens.accessToken;
      try {
        const data = await fetchClientAreaData(tokens.accessToken);
        setAccounts(data.accounts);
        setWallet(data.walletInfo);
      } finally {
        setIsLoading(false);
      }
    } else if (!tokens) {
      logout();
    }
  };

  useEffect(() => {
    if (!isTokensLoading) {
      fetchData();
    }
  }, [tokens, isTokensLoading]);

  useEffect(() => {
    if (!isTokensLoading && tokens?.accessToken && user) {
      const fetchDataProfile = async () => {
        try {
          const profileData = await fetchProfile(
            tokens.accessToken,
            user.userId,
          );
          setKycStatus(profileData.isKYCValidated);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchDataProfile();
    } else if (!tokens) {
      logout();
    }
  }, [tokens, isTokensLoading]);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [isIdCopied, setIsIdCopied] = useState<boolean>(false);
  const [isPasswordCopied, setIsPasswordCopied] = useState<boolean>(false);
  const [isServerCopied, setIsServerCopied] = useState<boolean>(false);

  const validatePassword = (
    password: string,
    confirmPassword: string,
  ): boolean => {
    const hasLowerCase = /[a-z]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecial = /[#@!]/.test(password);
    const isMinLength = password.length >= 8;

    if (
      !hasLowerCase ||
      !hasUpperCase ||
      !hasSpecial ||
      !hasNumber ||
      !isMinLength
    ) {
      setError(t('MODIFY_PASSWORD_MODAL.ERROR_PASSWORD_MUST_CONTAINS'));
      return false;
    }

    if (password !== confirmPassword) {
      setError(t('MODIFY_PASSWORD_MODAL.ERROR_PASSWORDS_DO_NOT_MATCH'));
      return false;
    }

    setError('');
    return true;
  };

  const handleSubmitModifyPassword = (
    e: any,
    login: number,
    password: string,
  ) => {
    e.preventDefault();

    const isValidPassword = validatePassword(password, confirmPassword);
    if (!isValidPassword) {
      return;
    }

    if (tokens?.accessToken && user) {
      const modifyPasswordRequest = async () => {
        try {
          modifyPassword(login, password, tokens.accessToken);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      modifyPasswordRequest();
      handleCloseModifyPasswordDialog(e);
      fetchData();
    } else if (!tokens) {
      logout();
    }
  };

  const handleCopyToClipboard = (
    text: string,
    type: 'id' | 'password' | 'server',
  ) => {
    navigator.clipboard.writeText(text);
    if (type === 'id') {
      setIsIdCopied(true);
      setTimeout(() => setIsIdCopied(false), 2000);
    } else if (type === 'password') {
      setIsPasswordCopied(true);
      setTimeout(() => setIsPasswordCopied(false), 2000);
    } else if (type === 'server') {
      setIsServerCopied(true);
      setTimeout(() => setIsServerCopied(false), 2000);
    }
  };

  const openModal = (accountLogin: string) => {
    setIsModalOpen({ ...isModalOpen, [accountLogin]: true });
  };

  const openWithdrawModal = () => {
    setIsWithdrawModalOpen(true);
  };

  const closeModal = (accountLogin: string) => {
    setIsModalOpen({ ...isModalOpen, [accountLogin]: false });
  };

  const closeWithdrawModal = () => {
    setIsWithdrawModalOpen(false);
  };

  const handleSelect = (option: string) => {
    if (option !== t('withdrawFunds.stepOne.selectPaiementMethodPlaceholder')) {
      setSelectedOption(option);
      setError('');
    }
  };

  const handleWalletChange = (e: any) => {
    const value = e.target.value;
    setWalletValue(value);
    setIsWalletValid(value.trim() !== '');
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'e' || e.key === 'E') {
      e.preventDefault();
    }
  };

  const handleNetworkChange = (
    event: React.ChangeEvent<{}>,
    value: { id: number; name: string; src: string } | null,
  ) => {
    setSelectedNetwork(value ? value.name : null);
  };

  const handleStepChange = (newStep: number) => {
    setError('');

    if (newStep > currentStep && currentStep === 0 && !selectedOption) {
      setError(t('withdrawFunds.stepOne.error'));
      return;
    }

    if (
      newStep > currentStep &&
      currentStep === 1 &&
      selectedOption === t('withdrawFunds.stepOne.cryptoWallet') &&
      !isWalletValid
    ) {
      setError(t('withdrawFunds.stepTwo.errorCrypto'));
      return;
    }
    setCurrentStep(newStep);
  };

  const sendToast = () => {
    toast.info(t('headerCards.PLEASE_COMPLETE_YOUR_KYC_TO_WITHDRAW'), {
      progressStyle: { backgroundColor: 'cornflowerblue' },
      autoClose: 15000,
    });
  };

  const handleWithdrawSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const numericAmount = parseFloat(amount.replace(',', '.'));

    if (numericAmount <= 0 || numericAmount > wallet?.currentBalance) {
      setError(t('withdrawFunds.stepThree.error'));
      return;
    }

    if (
      selectedOption === t('withdrawFunds.stepOne.bankAccount') &&
      numericAmount > 8400
    ) {
      setError(
        t('withdrawFunds.stepThree.ERROR_YOU_EXCEED_THE_MAXIMUM_AMOUNT'),
      );
      return;
    }

    if (tokens?.accessToken) {
      setIsLoading(true);
      const numericAmount = parseFloat(amount.replace(',', '.'));

      try {
        await submitWithdrawalRequest(
          tokens?.accessToken,
          numericAmount,
          selectedOption === t('withdrawFunds.stepOne.bankAccount')
            ? 'BANK'
            : 'CRYPTO',
          walletValue,
          selectedNetwork,
        );
        const data = await fetchClientAreaData(tokens.accessToken);
        setWallet(data.walletInfo);
      } catch (error) {
        setIsLoading(false);
        closeWithdrawModal();
      } finally {
        setIsLoading(false);
        closeWithdrawModal();
      }
    }
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    bgcolor: isDarkMode ? '#262729' : '#ffffff',
    color: isDarkMode ? '#ffffff' : '#262729',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
    maxHeight: '80vh',
    overflowY: 'auto',
  };

  if (isLoading) {
    return <Loader />;
  }

  return showContract ? (
    <PDFViewer file={ContractPDF} accountUUID={selectedAccountLogin} />
  ) : (
    <>
      <MUIModal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stepper
            steps={steps}
            currentStep={currentStep}
            onStepChange={handleStepChange}
            className={classes.stepper}
          >
            <form>
              {currentStep === 0 && (
                <div>
                  {error && <Error error={error} />}
                  <h2>{t('withdrawFunds.stepOne.selectPaiementMethod')}</h2>
                  <Select
                    id="paymentMethod"
                    options={paymentMethod}
                    onSelect={handleSelect}
                  />
                </div>
              )}
              {currentStep === 1 && (
                <div>
                  {error && <Error error={error} />}

                  <h2 className="h2-container">
                    {selectedOption === t('withdrawFunds.stepOne.cryptoWallet')
                      ? t('withdrawFunds.stepTwo.enterCryptoWallet')
                      : null}
                    {selectedOption ===
                    t('withdrawFunds.stepOne.cryptoWallet') ? (
                      <Tooltip
                        content={
                          selectedOption ===
                          t('withdrawFunds.stepOne.cryptoWallet')
                            ? t('withdrawFunds.stepTwo.helpCrypto')
                            : null
                        }
                        className="tooltip-text"
                      >
                        <Tag
                          label={``}
                          color="#ffffff"
                          icon={
                            <Icon
                              fill="#ffffff"
                              color=""
                              size={16}
                              path={
                                'M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 11V17H13V11H11ZM11 7V9H13V7H11Z'
                              }
                            />
                          }
                        />
                      </Tooltip>
                    ) : null}
                  </h2>
                  {selectedOption === t('withdrawFunds.stepOne.bankAccount') ? (
                    <>
                      <Typography variant="h5">
                        {t('withdrawFunds.stepTwo.BANK_INFORMATION_MESSAGE')}
                      </Typography>
                    </>
                  ) : (
                    <div>
                      <Input
                        className={classes.stepperComponents}
                        placeholder={t(
                          'withdrawFunds.stepTwo.cryptoWalletPlaceholder',
                        )}
                        value={walletValue}
                        onChange={handleWalletChange}
                        required
                      />

                      <Autocomplete
                        id="select-network"
                        options={networkList}
                        getOptionLabel={(option) => option.name}
                        onChange={handleNetworkChange}
                        sx={{
                          marginTop: '20px',
                          zIndex: 6000,
                        }}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{
                              '& > img': { mr: 2, flexShrink: 0 },
                            }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              srcSet={option.src}
                              src={option.src}
                              alt=""
                            />
                            {option.name}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            className={classes.networkLabel}
                            {...params}
                            label={t(
                              'withdrawFunds.stepTwo.selectNetworkPlaceholder',
                            )}
                            inputProps={{
                              ...params.inputProps,
                            }}
                            sx={{
                              '& .MuiInputBase-input': {
                                color: isDarkMode ? 'white' : '#333333',
                              },
                              '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                  borderColor: isDarkMode
                                    ? '#4f4f51'
                                    : '#d4d4d4',
                                  borderWidth: '2px',
                                },
                                '&:hover fieldset': {
                                  borderColor: isDarkMode
                                    ? '#4f4f51'
                                    : '#d4d4d4',
                                },
                                '&.Mui-focused fieldset': {
                                  borderColor: '#e73d44',
                                },
                              },
                              '& .MuiSvgIcon-root': {
                                color: isDarkMode ? 'white' : '#333333',
                              },
                              '& .MuiInputLabel-root': {
                                color: isDarkMode ? 'white' : '#333333',
                              },
                            }}
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
              )}
              {currentStep === 2 && (
                <div>
                  {error && <Error error={error} />}
                  <h2>{t('withdrawFunds.stepThree.enterTheAmount')}</h2>
                  <p className="max-balance-text">
                    {t('withdrawFunds.stepThree.BALANCE')}{' '}
                    {wallet?.currentBalance}€
                  </p>
                  {selectedOption ===
                    t('withdrawFunds.stepOne.bankAccount') && (
                    <p className="max-balance-text">
                      {t('withdrawFunds.stepThree.max')} 8400€
                    </p>
                  )}

                  <Input
                    className={classes.stepperComponents}
                    id="amount"
                    type="text"
                    placeholder={t(
                      'withdrawFunds.stepThree.enterAmountPlaceholder',
                    )}
                    onKeyDown={handleKeyDown}
                    value={amount === '0' ? '' : amount}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      let formattedValue = inputValue
                        .replace(',', '.')
                        .replace(/[^0-9.]+/g, '');

                      const parts = formattedValue.split('.');
                      if (parts.length > 2) {
                        formattedValue =
                          parts[0] + '.' + parts.slice(1).join('');
                      }

                      setAmount(formattedValue);
                    }}
                  />

                  <Button
                    className={classes.stepperComponents}
                    variant="primary"
                    onClick={(e) => {
                      handleWithdrawSubmit(e);
                    }}
                  >
                    {t('headerCards.withdrawButton')}
                  </Button>
                </div>
              )}
            </form>
          </Stepper>
        </Box>
      </MUIModal>

      <MUIModal open={openGetFunded} onClose={handleCloseGetFunded}>
        <Box sx={style}>
          <Stack gap={5} alignItems={'flex-start'}>
            <Typography variant="h4">{t('SIGN_CONTRACT.TITLE')}</Typography>
            <Typography>{t('SIGN_CONTRACT.TEXT')}</Typography>
            <MUIButton
              variant="contained"
              onClick={() => {
                setShowContract(true);
                handleCloseGetFunded();
              }}
            >
              {t('SIGN_CONTRACT.BUTTON')}
            </MUIButton>
          </Stack>
        </Box>
      </MUIModal>

      <div className={classes.cardsContainer}>
        <IconCard
          title={t('headerCards.challengeActive')}
          amount={0 || wallet?.activeChallenges}
          icon="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16ZM12 18C8.68629 18 6 15.3137 6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 15.3137 15.3137 18 12 18ZM12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14Z"
          className={classes.card}
        />
        <IconCard
          title={t('headerCards.fundedAccount')}
          amount={0 || wallet?.fundedAccounts}
          icon="M2 20H22V22H2V20ZM4 12H6V19H4V12ZM9 12H11V19H9V12ZM13 12H15V19H13V12ZM18 12H20V19H18V12ZM2 7L12 2L22 7V11H2V7ZM12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8Z"
          className={classes.card}
        />
        <IconCard
          title={t('headerCards.currentWallet')}
          amount={`${0 || wallet?.currentBalance}€`}
          icon="M2.00488 9H21.0049C21.5572 9 22.0049 9.44772 22.0049 10V20C22.0049 20.5523 21.5572 21 21.0049 21H3.00488C2.4526 21 2.00488 20.5523 2.00488 20V9ZM3.00488 3H18.0049V7H2.00488V4C2.00488 3.44772 2.4526 3 3.00488 3ZM15.0049 14V16H18.0049V14H15.0049Z"
          percent={0 || wallet?.walletVariation}
          button={
            <div className={classes.withdrawButtons}>
              <Button
                variant={kycStatus ? 'primary' : 'disabled'}
                onClick={kycStatus ? handleOpen : sendToast}
              >
                {t('headerCards.withdrawButton')}
              </Button>
            </div>
          }
          className={classes.lastCard}
        />
      </div>

      <div className={classes.challengesContainer}>
        <h2 className={classes.sectionTitle}>
          <Icon
            color="black"
            size={32}
            path="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16ZM12 18C8.68629 18 6 15.3137 6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 15.3137 15.3137 18 12 18ZM12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14Z"
          />
          {t('main.challengeSectionTitle')}
        </h2>
        <div className={classes.accountCardsContainer}>
          {accounts.map((account: any) => {
            let buttonText = '';

            if (account?.phase === 'Phase 1') {
              buttonText = t('accountCard.startStepTwo', { ns: 'components' });
            } else if (account?.phase === 'Phase 2') {
              buttonText = t('accountCard.startStepFunded', {
                ns: 'components',
              });
            }

            return (
              <AccountCard
                key={account.accountLogin}
                accountNumber={account?.accountLogin}
                balance={account?.balance}
                result={t('accountCard.inProgress', { ns: 'components' })}
                phase={account?.phase}
                upgradeButton={
                  account?.canUpgrade ? (
                    <Button
                      onClick={() => {
                        buttonText ===
                        t('accountCard.startStepFunded', {
                          ns: 'components',
                        })
                          ? handleOpenGetFunded(account?.challengeUUID)
                          : upgradeAccountPhase(account, tokens?.accessToken);
                      }}
                      variant="primary"
                    >
                      {buttonText}
                    </Button>
                  ) : null
                }
                onClick={() =>
                  handleOpenCredentialsModal(account?.accountLogin)
                }
              >
                <div className={classes.accountContent}>
                  <Card className={classes.chart}>
                    <ZoomableChart
                      labels={Array.from(
                        { length: account?.listOfProfits.length },
                        (_, i) => i.toString(),
                      )}
                      datasets={[
                        {
                          label: 'Profits',
                          data: account?.listOfProfits,
                          fill: 'start',
                          backgroundColor: 'rgba(231, 71, 80, 0.1)',
                          borderColor: 'rgb(231, 71, 80)',
                        },
                      ]}
                      xAxisTitle="Number of trades"
                      yAxisTitle="Profits"
                      annotations={[
                        {
                          id: 'maxDailyLoss',
                          content: `Max Daily Loss: ${account?.maxDailyLossChart}`,
                          borderColor: 'rgb(231, 71, 80)',
                          yMin: account?.maxDailyLossChart,
                          yMax: account?.maxDailyLossChart,
                        },
                        {
                          id: 'maxLoss',
                          content: `Max Loss: ${account?.maxLossPassed.targetValue}`,
                          borderColor: 'rgb(231, 71, 80)',
                          yMin: account?.maxLossPassed.targetValue,
                          yMax: account?.maxLossPassed.targetValue,
                        },
                        {
                          id: 'profitTarget',
                          content: `Profit Target: ${account?.profitTargetPassed.targetValue}`,
                          borderColor: 'rgb(31,204,146)',
                          yMin: account?.profitTargetPassed.targetValue,
                          yMax: account?.profitTargetPassed.targetValue,
                        },
                      ]}
                    />
                  </Card>
                  <table
                    className={`${classes.challengesTable} ${
                      isDarkMode ? classes.dark : classes.light
                    }`}
                  >
                    <tbody className={classes.challengesTable__body}>
                      <tr>
                        <td>
                          {t('accountCard.accountTable.status', {
                            ns: 'components',
                          })}
                        </td>
                        <td className={classes.status}>
                          {t('accountCard.inProgress', { ns: 'components' })} -{' '}
                          {account?.phase}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {t('accountCard.accountTable.startDate', {
                            ns: 'components',
                          })}
                        </td>
                        <td>{account?.startDate}</td>
                      </tr>
                      <tr>
                        <td>
                          {t('accountCard.accountTable.deposit', {
                            ns: 'components',
                          })}
                        </td>
                        <td>{account?.deposit}$</td>
                      </tr>
                      <tr>
                        <td>
                          {t('accountCard.accountTable.profit', {
                            ns: 'components',
                          })}
                        </td>
                        <td>{account?.profit}$</td>
                      </tr>
                      <tr>
                        <td>
                          {t('accountCard.accountTable.balance', {
                            ns: 'components',
                          })}
                        </td>
                        <td>{account?.balance}$</td>
                      </tr>
                      <tr>
                        <td>
                          {t('accountCard.accountTable.withdraw', {
                            ns: 'components',
                          })}
                        </td>
                        <td>{account?.withdraw}$</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <Stack
                  sx={{
                    padding: '0 2vw',
                  }}
                >
                  <Stack
                    sx={{
                      position: 'relative',
                      marginBottom: '80px',
                      marginTop: '80px',
                      border: '2px solid #CCCCCC',
                      width: '100%',
                      height: '16px',
                      borderRadius: '50px',
                    }}
                  >
                    <Stack
                      sx={{
                        width: `${account?.consistencyRule.currentPercentage}%`,
                        maxWidth: '100%',
                        backgroundColor: '#E74750',
                        height: '14px',
                        borderRadius: '50px',
                      }}
                    >
                      <Stack
                        sx={{
                          position: 'absolute',
                          height: '38px',
                          width: '2px',
                          backgroundColor: '#E74750',
                          top: '0',
                          bottom: '0',
                          margin: 'auto 0',
                          left: `min(${account?.consistencyRule.currentPercentage}%, 100%)`,
                          '::before': {
                            textWrap: 'nowrap',
                            whiteSpace: 'nowrap',
                            content: `"${t('PROGRESS_BAR.ACTUAL')} ${account
                              ?.consistencyRule.currentPercentage}%"`,
                            position: 'absolute',
                            top: '-50px',
                            left: '50%',
                            transform:
                              account?.consistencyRule.currentPercentage < 5
                                ? 'translateX(-0%)'
                                : account?.consistencyRule.currentPercentage >
                                  90
                                ? 'translateX(-100%)'
                                : 'translateX(-50%)',
                            fontWeight: 'bold',
                            color: '#E74750',
                          },
                          '::after': {
                            textWrap: 'nowrap',
                            whiteSpace: 'nowrap',
                            content: `"${t(
                              'PROGRESS_BAR.MAX_DAILY_PROFIT',
                            )} ${account?.consistencyRule.maxDailyProfit}$"`,
                            position: 'absolute',
                            bottom: '-50px',
                            left: '50%',
                            transform:
                              account?.consistencyRule.currentPercentage < 5
                                ? 'translateX(-0%)'
                                : account?.consistencyRule.currentPercentage >
                                  90
                                ? 'translateX(-100%)'
                                : 'translateX(-50%)',
                            fontWeight: 'bold',
                          },
                        }}
                      ></Stack>
                    </Stack>

                    <Stack
                      sx={{
                        position: 'absolute',
                        height: '38px',
                        width: '2px',
                        backgroundColor: '#CCCCCC',
                        top: '0',
                        bottom: '0',
                        margin: 'auto 0',
                        left: '45%',
                        '::before': {
                          textWrap: 'nowrap',
                          whiteSpace: 'nowrap',
                          content: `"${t('PROGRESS_BAR.45_PERCENT_RULE')}"`,
                          position: 'absolute',
                          top: '-30px',
                          left: '50%',
                          transform: 'translateX(-50%)',
                          fontWeight: 'bold',
                        },
                        '::after': {
                          textWrap: 'nowrap',
                          whiteSpace: 'nowrap',
                          content: `"${account?.consistencyRule.minimumPayoutValue}$"`,
                          position: 'absolute',
                          bottom: '-30px',
                          left: '50%',
                          transform: 'translateX(-50%)',
                          fontWeight: 'bold',
                        },
                      }}
                    ></Stack>
                  </Stack>
                </Stack>
                <div className={classes.objectives}>
                  <table
                    className={`${classes.table} ${
                      isDarkMode ? classes.dark : classes.light
                    }`}
                  >
                    <thead className={classes.table__header}>
                      <tr>
                        <th colSpan={2}>
                          {t('accountCard.objectivesTable.tradingObjectives', {
                            ns: 'components',
                          })}
                        </th>
                        <th>
                          {t('accountCard.objectivesTable.profitTarget', {
                            ns: 'components',
                          })}
                        </th>
                        <th>
                          {t('accountCard.objectivesTable.currentResult', {
                            ns: 'components',
                          })}
                        </th>
                        <th>
                          {t('accountCard.objectivesTable.state', {
                            ns: 'components',
                          })}
                        </th>
                        <th>Valeur limite</th>
                      </tr>
                    </thead>
                    <tbody className={classes.table__body}>
                      <tr>
                        <td colSpan={2}>
                          {t('accountCard.objectivesTable.maxDailyLoss', {
                            ns: 'components',
                          })}
                        </td>
                        <td>{account?.maxDailyLossPassed.targetValue}$</td>
                        <td>{account?.maxDailyLossPassed.currentValue}$</td>
                        <td className={classes.state}>
                          <Icon
                            size={18}
                            color=""
                            fill={
                              account?.maxDailyLossPassed.passed
                                ? '#1fcc92'
                                : '#e74750'
                            }
                            path={
                              account?.maxDailyLossPassed.passed
                                ? 'M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z'
                                : 'M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z'
                            }
                          />
                          {account?.maxDailyLossPassed.passed
                            ? t('accountCard.objectivesTable.passed', {
                                ns: 'components',
                              })
                            : t('accountCard.objectivesTable.notPassed', {
                                ns: 'components',
                              })}
                        </td>
                        <td>{account?.maxDailyLossPassed.limitValue}</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          {t('accountCard.objectivesTable.maxLoss', {
                            ns: 'components',
                          })}
                        </td>
                        <td>{account?.maxLossPassed.targetValue}$</td>
                        <td>{account?.maxLossPassed.currentValue}$</td>
                        <td className={classes.state}>
                          <Icon
                            size={18}
                            color=""
                            fill={
                              account?.maxLossPassed.passed
                                ? '#1fcc92'
                                : '#e74750'
                            }
                            path={
                              account?.maxLossPassed.passed
                                ? 'M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z'
                                : 'M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z'
                            }
                          />
                          {account?.maxLossPassed.passed
                            ? t('accountCard.objectivesTable.passed', {
                                ns: 'components',
                              })
                            : t('accountCard.objectivesTable.notPassed', {
                                ns: 'components',
                              })}
                        </td>
                        <td>{account?.maxLossPassed.limitValue}</td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          {t('accountCard.objectivesTable.profitTarget', {
                            ns: 'components',
                          })}
                        </td>
                        <td>{account?.profitTargetPassed.targetValue}$</td>
                        <td>{account?.profitTargetPassed.currentValue}$</td>
                        <td className={classes.state}>
                          <Icon
                            size={18}
                            color=""
                            fill={
                              account?.profitTargetPassed.passed
                                ? '#1fcc92'
                                : '#e74750'
                            }
                            path={
                              account?.profitTargetPassed.passed
                                ? 'M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z'
                                : 'M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z'
                            }
                          />
                          {account?.profitTargetPassed.passed
                            ? t('accountCard.objectivesTable.passed', {
                                ns: 'components',
                              })
                            : t('accountCard.objectivesTable.notPassed', {
                                ns: 'components',
                              })}
                        </td>
                        <td>{account?.profitTargetPassed.limitValue}</td>
                      </tr>

                      <tr>
                        <td colSpan={2}>{t('PROGRESS_BAR.45_PERCENT_RULE')}</td>
                        <td>45%</td>
                        <td>{account?.consistencyRule.currentPercentage}%</td>
                        <td className={classes.state}>
                          <Icon
                            size={18}
                            color=""
                            fill={
                              account?.consistencyRule.valid
                                ? '#1fcc92'
                                : '#e74750'
                            }
                            path={
                              account?.consistencyRule.valid
                                ? 'M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z'
                                : 'M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z'
                            }
                          />
                          {account?.consistencyRule.valid
                            ? t('accountCard.objectivesTable.passed', {
                                ns: 'components',
                              })
                            : t('accountCard.objectivesTable.notPassed', {
                                ns: 'components',
                              })}
                        </td>
                        <td>{account?.consistencyRule.minimumPayoutValue}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </AccountCard>
            );
          })}

          {accounts.map((account: any) => (
            <>
              <MUIModal
                key={account.accountLogin + '_modal'}
                open={openCredentialsModal[account.accountLogin]}
                onClose={handleCloseCredentialsModal}
              >
                <Box sx={style}>
                  <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                    {t('credentialsModal.title')}
                  </Typography>
                  <div className={classes.credentials}>
                    <p>{t('credentialsModal.login')}</p>
                    <p ref={idRef}>{account?.accountLogin}</p>
                    <p
                      className={classes.copyButton}
                      onClick={() =>
                        handleCopyToClipboard(account?.accountLogin, 'id')
                      }
                    >
                      <Icon
                        size={24}
                        color="white"
                        path="M6.9998 6V3C6.9998 2.44772 7.44752 2 7.9998 2H19.9998C20.5521 2 20.9998 2.44772 20.9998 3V17C20.9998 17.5523 20.5521 18 19.9998 18H16.9998V20.9991C16.9998 21.5519 16.5499 22 15.993 22H4.00666C3.45059 22 3 21.5554 3 20.9991L3.0026 7.00087C3.0027 6.44811 3.45264 6 4.00942 6H6.9998ZM8.9998 6H16.9998V16H18.9998V4H8.9998V6Z"
                      />
                      {isIdCopied
                        ? t('copied', { ns: 'common' })
                        : t('copy', { ns: 'common' })}
                    </p>
                  </div>
                  <hr className={classes.credentialsLine} />
                  <div className={classes.credentials}>
                    <p>{t('credentialsModal.password')}</p>
                    <div
                      className={`${classes.credentialsPassword} ${
                        isDarkMode ? classes.dark : classes.light
                      }`}
                    >
                      <input
                        type={showPassword ? 'text' : 'password'}
                        value={account?.accountPassword}
                        readOnly
                        ref={passwordRef}
                      />
                      <Icon
                        onClick={() => handleShowPassword()}
                        size={24}
                        color="white"
                        path={
                          showPassword
                            ? 'M4.52047 5.93457L1.39366 2.80777L2.80788 1.39355L22.6069 21.1925L21.1927 22.6068L17.8827 19.2968C16.1814 20.3755 14.1638 21.0002 12.0003 21.0002C6.60812 21.0002 2.12215 17.1204 1.18164 12.0002C1.61832 9.62282 2.81932 7.5129 4.52047 5.93457ZM14.7577 16.1718L13.2937 14.7078C12.902 14.8952 12.4634 15.0002 12.0003 15.0002C10.3434 15.0002 9.00026 13.657 9.00026 12.0002C9.00026 11.537 9.10522 11.0984 9.29263 10.7067L7.82866 9.24277C7.30514 10.0332 7.00026 10.9811 7.00026 12.0002C7.00026 14.7616 9.23884 17.0002 12.0003 17.0002C13.0193 17.0002 13.9672 16.6953 14.7577 16.1718ZM7.97446 3.76015C9.22127 3.26959 10.5793 3.00016 12.0003 3.00016C17.3924 3.00016 21.8784 6.87992 22.8189 12.0002C22.5067 13.6998 21.8038 15.2628 20.8068 16.5925L16.947 12.7327C16.9821 12.4936 17.0003 12.249 17.0003 12.0002C17.0003 9.23873 14.7617 7.00016 12.0003 7.00016C11.7514 7.00016 11.5068 7.01833 11.2677 7.05343L7.97446 3.76015Z'
                            : 'M1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12ZM12.0003 17C14.7617 17 17.0003 14.7614 17.0003 12C17.0003 9.23858 14.7617 7 12.0003 7C9.23884 7 7.00026 9.23858 7.00026 12C7.00026 14.7614 9.23884 17 12.0003 17ZM12.0003 15C10.3434 15 9.00026 13.6569 9.00026 12C9.00026 10.3431 10.3434 9 12.0003 9C13.6571 9 15.0003 10.3431 15.0003 12C15.0003 13.6569 13.6571 15 12.0003 15Z'
                        }
                      />
                    </div>
                    <p
                      className={classes.copyButton}
                      onClick={() =>
                        handleCopyToClipboard(
                          account?.accountPassword,
                          'password',
                        )
                      }
                    >
                      <Icon
                        size={24}
                        color="white"
                        path="M6.9998 6V3C6.9998 2.44772 7.44752 2 7.9998 2H19.9998C20.5521 2 20.9998 2.44772 20.9998 3V17C20.9998 17.5523 20.5521 18 19.9998 18H16.9998V20.9991C16.9998 21.5519 16.5499 22 15.993 22H4.00666C3.45059 22 3 21.5554 3 20.9991L3.0026 7.00087C3.0027 6.44811 3.45264 6 4.00942 6H6.9998ZM8.9998 6H16.9998V16H18.9998V4H8.9998V6Z"
                      />
                      {isPasswordCopied
                        ? t('copied', { ns: 'common' })
                        : t('copy', { ns: 'common' })}
                    </p>
                    <MuiTooltip title="Modifier le mot de passe">
                      <IconButton
                        aria-label="modify password"
                        onClick={() => {
                          handleCloseCredentialsModal(account?.accountLogin);
                          handleOpenModifyPasswordDialog(account?.accountLogin);
                        }}
                        edge="start"
                        sx={{
                          marginLeft: 1,
                          '&:hover': {
                            backgroundColor: isDarkMode
                              ? 'rgba(255, 255, 255, 0.2)'
                              : 'rgba(149, 143, 143, 0.2)',
                          },
                          color: isDarkMode ? '#FFFFFF' : '#333333',
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </MuiTooltip>
                  </div>
                  <hr className={classes.credentialsLine} />
                  <div className={classes.credentials}>
                    <p>{t('credentialsModal.server')}</p>
                    <p ref={serverRef}>{account?.server}</p>
                    <p
                      className={classes.copyButton}
                      onClick={() =>
                        handleCopyToClipboard(account?.server, 'server')
                      }
                    >
                      <Icon
                        size={24}
                        color="white"
                        path="M6.9998 6V3C6.9998 2.44772 7.44752 2 7.9998 2H19.9998C20.5521 2 20.9998 2.44772 20.9998 3V17C20.9998 17.5523 20.5521 18 19.9998 18H16.9998V20.9991C16.9998 21.5519 16.5499 22 15.993 22H4.00666C3.45059 22 3 21.5554 3 20.9991L3.0026 7.00087C3.0027 6.44811 3.45264 6 4.00942 6H6.9998ZM8.9998 6H16.9998V16H18.9998V4H8.9998V6Z"
                      />
                      {isServerCopied
                        ? t('copied', { ns: 'common' })
                        : t('copy', { ns: 'common' })}
                    </p>
                  </div>
                </Box>
              </MUIModal>

              <MUIModal
                key={account.accountLogin}
                open={openModifyPasswordDialog[account.accountLogin]}
                onClose={handleCloseModifyPasswordDialog}
              >
                <Box sx={style}>
                  {error && <Error error={error} />}

                  <Stack
                    justifyContent={'center'}
                    alignItems={'center'}
                    spacing={5}
                  >
                    <Stack style={{ width: '90%' }}>
                      <label>Password</label>
                      <Input
                        autoFocus
                        required
                        type="password"
                        value={password}
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Stack>

                    <Stack style={{ width: '90%' }}>
                      <label>Confirm your password</label>
                      <Input
                        required
                        type="password"
                        value={confirmPassword}
                        placeholder="Confirm your password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </Stack>
                    <Button
                      variant="primary"
                      onClick={(e) =>
                        handleSubmitModifyPassword(
                          e,
                          account.accountLogin,
                          password,
                        )
                      }
                    >
                      Modify Password
                    </Button>
                  </Stack>
                </Box>
              </MUIModal>
            </>
          ))}
        </div>
      </div>
      <div className={classes.infoCardsContainer}>
        <Card className={classes.infoCard}>
          <div className={classes.iconContainer}>
            <div className={classes.icon}>
              <Icon
                color="black"
                size={34}
                path="M3 3H12.382C12.7607 3 13.107 3.214 13.2764 3.55279L14 5H20C20.5523 5 21 5.44772 21 6V17C21 17.5523 20.5523 18 20 18H13.618C13.2393 18 12.893 17.786 12.7236 17.4472L12 16H5V22H3V3Z"
              />
            </div>
          </div>
          <div>
            <h2>{t('startNewChallengeCard.startNewChallengeTitle')}</h2>
            <h3>{t('startNewChallengeCard.startNewChallengeSubtitle')}</h3>
          </div>
          <ul>
            <li>
              <Icon
                color="white"
                size={20}
                path="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"
              />
              {t('startNewChallengeCard.maximizeYourPotential')}
            </li>
            <li>
              <Icon
                color="white"
                size={20}
                path="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"
              />
              {t('startNewChallengeCard.comprensiveSupportNetwork')}
            </li>
            <li>
              <Icon
                color="white"
                size={20}
                path="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11.0026 16L18.0737 8.92893L16.6595 7.51472L11.0026 13.1716L8.17421 10.3431L6.75999 11.7574L11.0026 16Z"
              />
              {t('startNewChallengeCard.unlockNewOpportunities')}
            </li>
          </ul>
          <Button variant="primary" onClick={() => navigate('/buy-challenge')}>
            {t('startNewChallengeCard.startNewChallengeButton')}
          </Button>
        </Card>
        <Card className={classes.infoCard}>
          <div className={classes.iconContainer}>
            <div className={classes.icon}>
              <Icon
                color="black"
                size={34}
                path="M12 19C12.8284 19 13.5 19.6716 13.5 20.5C13.5 21.3284 12.8284 22 12 22C11.1716 22 10.5 21.3284 10.5 20.5C10.5 19.6716 11.1716 19 12 19ZM12 2C15.3137 2 18 4.68629 18 8C18 10.1646 17.2474 11.2907 15.3259 12.9231C13.3986 14.5604 13 15.2969 13 17H11C11 14.526 11.787 13.3052 14.031 11.3989C15.5479 10.1102 16 9.43374 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8V9H6V8C6 4.68629 8.68629 2 12 2Z"
              />
            </div>
          </div>
          <div className={classes.content}>
            <h2>{t('haveQuestionCard.haveQuestionTitle')}</h2>
            <h3 className={classes.subtitle}>
              {t('haveQuestionCard.haveQuestionSubtitle')}
            </h3>
            <p>{t('haveQuestionCard.haveQuestionText')}</p>
          </div>
          <Button
            variant="outline"
            onClick={() => window.open(`https://raisemyfunds.co/faq/`)}
          >
            {t('haveQuestionCard.haveQuestionButton')}
          </Button>
        </Card>
      </div>
    </>
  );
}
